import React from 'react';
import { IFooterContainerProps } from '../../../FooterContainer.types';
import ShadowTopScreen from '../../../../ScreenWidthContainer/viewer/skinComps/ShadowTopScreen/ShadowTopScreen';
import FooterContainer from '../../FooterContainer';

const ShadowTopScreenFooter: React.FC<Omit<IFooterContainerProps, 'skin'>> =
  props => (
    <FooterContainer {...props} skin={ShadowTopScreen}></FooterContainer>
  );

export default ShadowTopScreenFooter;
