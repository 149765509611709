import FooterContainer_ShadowTopScreenComponent from '@wix/thunderbolt-elements/src/components/FooterContainer/viewer/skinComps/ShadowTopScreen/ShadowTopScreen.skin';


const FooterContainer_ShadowTopScreen = {
  component: FooterContainer_ShadowTopScreenComponent
};


export const components = {
  ['FooterContainer_ShadowTopScreen']: FooterContainer_ShadowTopScreen
};


// temporary export
export const version = "1.0.0"
